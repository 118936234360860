<template>
    <div class="event-page">
        <base-nav />

        <base-inner-banner img-type="event"  />

        <b-container class="position-relative">
            <h2 class="text-center page-h1 mb-0 pb-0">{{ $t('Nav_event')}} </h2>

            <base-loading  class="mt-5" :is-loading="isLoading" />

            <!-- the lastest-->
            <template v-show="!isLoading">
                <b-row class="latest-event">
                    <b-col>
                        <event-item
                            :event="newestEvent"
                            item-direction="row"/> 
                    </b-col>
                </b-row>

                <div class="tabs">
                    <!-- arrow -->
                    <div class="mobile-event-tab-control">
                        <div class="tab-control-left tab-control-btn" @click="setTabIndex(-1)">
                            <b-icon icon="chevron-left"></b-icon>
                        </div>
                        <div class="tab-control-right tab-control-btn" @click="setTabIndex(1)">
                            <b-icon icon="chevron-right"></b-icon>
                        </div>
                    </div>

                    <ul class="nav justify-content-center event-tabs">
                        <li v-for="({value, text}, index) in tabs" :key="value">
                            <div
                                @click="clickTab(index)"
                                :class="{'active': tabIndex == index}"
                                class="nav-link event-link">
                                <h3 class="tab-title">{{ text[currentLang] }}</h3>
                            </div>
                        </li>
                    </ul>
                </div>

                <b-row class="category-enent position-relative">
                    <div
                        v-for="event in eventList"
                        :key="event._id"
                        class="event-col">
                        <event-item
                            :event="event"
                            item-direction="column" /> 
                    </div>
                </b-row>
            </template>
        </b-container>

        <base-footer />
    </div>
</template>

<script>
import { getCategories } from '@/apis/apiEventCategories';
import { getEvents } from '@/apis/apiEvents';
import EventItem from '@/components/events/EventItem.vue';

export default {
    name: 'event',

    metaInfo() {
        return {
            title: this.$t('Nav_event'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('Head_description')
                } ,{
                    name: 'keywords',
                    content: this.$t('Head_keywords')
                }, {
                    name: 'author',
                    content: this.$t('Head_author')
                }, {
                    name: 'copyright',
                    content: this.$t('Head_copyright')
                }
            ]
        }
    },

    components: {
        EventItem,
    },

    computed: {
        currentLang() {
            const locale = this.$i18n.locale;
            
            if (locale.includes('en')) {
                return 'en';
            } else {
                return 'zh';
            }
        }
    },

    data() {
        return {
            tabIndex: 0,
            tabs: [],
            eventList: [],
            newestEvent: {},
            isLoading: false,
        }
    },

    created() {
        this.initialView();
    },


    methods: {
        setTabIndex(state) {
            const maxIndex = this.tabs.length - 1;
            let tmpIndex = this.tabIndex + state;

            if (tmpIndex < 0) {
                tmpIndex = maxIndex;
            } else if (tmpIndex > maxIndex) {
                tmpIndex = 0
            }

            this.tabIndex = tmpIndex;
            this.clickTab(tmpIndex);
        },

        async initialView() {
            this.isLoading = true;
            const categories = await getCategories();

            let categoryId = categories[0] ? categories[0].value : '';
        
            let queryCategory = this.$route.query.category;
            if (queryCategory) {
                const currentLang = this.$i18n.locale.includes('en') ? 'en' : 'zh';
                const activeTabIndex = categories.findIndex(item => {
                    return item['text'][currentLang] === queryCategory;
                })

                this.tabIndex = activeTabIndex > -1 ? activeTabIndex : 0;
                categoryId = categories[this.tabIndex].value;
            } 

            this.tabs = categories;
            const eventData = await getEvents({
                currentPage: 1,
                perPageItems: 50,
                category: categoryId,
            });

            this.eventList = eventData.eventList;
            this.getTheNewestEvent();
            this.isLoading = false;
        },

        async clickTab(index) {
            this.tabIndex = index;
            const currentLang = this.$i18n.locale.includes('en') ? 'en' : 'zh'; 
            const categoryId = this.tabs[index] ? this.tabs[index]['value'] : '';
            const category = this.tabs[index] ? this.tabs[index]['text'][currentLang] : '';

            const eventData = await getEvents({
                currentPage: 1,
                perPageItems: 50,
                category:categoryId, 
            });

            this.eventList = eventData.eventList;
            location.href = location.origin+'/events?category='+ category;
        },

        async getTheNewestEvent() {
            const eventData = await getEvents({
                currentPage: 1,
                perPageItems: 1,
                category: '',
            })
            this.newestEvent = eventData.eventList[0];
        }
    }
}
</script>

<style lang="scss" scoped>
$event-col-padding: 8px;

.tabs {
    position: relative;
    padding-bottom: 10px;
}

.event-page{
    background: $light-gray-color;
}

.category-enent{
    margin-left: -$event-col-padding;
    margin-right: -$event-col-padding;
    margin-bottom: 40px;
}

.latest-event{
    padding-top: 60px;
    padding-bottom: 75px;
}

.event-col{
    padding: 0 $event-col-padding;
    flex: 0 0 33.3%;
    margin-bottom: 40px;
    max-width: 33.3%;
}

.tab-title{
    font-size: 24px;
    margin-top: -8px;
    color: #747474;
    padding-top: 0;
    font-weight: 400;
    position: relative;
    margin-bottom: 20px;
    transition: color 0.2s ease;
    font-family: $font-family;
}

.active .tab-title{
    color: $color_primary;
    position: relative;
    &:after {
        content:"";
        position: absolute;
        border-bottom: 3px solid;
        left: 0;
        bottom: -10px;
        display: block;
        width: 100%;
    }
}

.event-link{
    cursor: pointer;
}

.mobile-event-tab-control{
    display: none;
}

@media screen and (max-width: 812px) {
    .event-col{
        flex: 0 0 50%;
        max-width: 50%;
    }
}


@media screen and (max-width: 576px) {
    .latest-event{
        display: none;
    }

    .tabs {
        padding-bottom: 0px;
        padding-top: 25px;
    }

    .event-col{
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }

    .mobile-event-tab-control{
        display: block;
        position: absolute;
        top: 32px;
        left: 0;
        width: 100%;
    }

    .nav-link{
        padding: 0.5rem;
    }

    .tab-control-btn{
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        top: 8px;
        z-index: 3;
        cursor: pointer;
    }

    .tab-control-right{
        right: 0;
    }

    .tab-control-left{
        left:0;
    }

    .tab-title {
        color: transparent;
        width: 12px;
        height: 30px;
        transition: none;
        &:after {
            content:"";
            position: absolute;
            border-bottom: 3px solid #e6e6e6;
            left: 0;
            bottom: -10px;
            display: block;
            width: 100%;
        }
    }

    .active .tab-title {
        width: auto;
    }

    .category-enent{
        margin-bottom: 20px;
    }
}

</style>
