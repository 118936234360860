<template>
    <div
        v-if="currentLang && Object.keys(event).length > 0 && event[currentLang].title "
        :class="[ itemDirection === 'column' ? 'column-sort' : 'row-sort']"
        class="evnet-item">

        <div @click="loadToEventPage(event)" class="event-photo overflow-hidden">
            <img :src="event.cover" :alt="event[currentLang].title" loading="lazy">
        </div>

        <div  class="event-info">
            <h2 class="sub-title" @click="loadToEventPage(event)">
                {{ event[currentLang].title }}
            </h2>
            <div class="activityDate">{{ event.activityDate }}</div>
            <h3 class="event-description">
                {{ event[currentLang].description }}
            </h3>

            <BaseMore class="more" :lead-to="`/events/${event.category[currentLang]}/${event[currentLang].title}`">
                {{ $t('MoreEventDetail') }}
            </BaseMore>
        </div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,

    name: 'eventItem',

    props: {
        itemDirection: {
            type: String,
            default: 'column'
        },

        event: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {}
    },

    computed: {
        currentLang() {
            const locale = this.$i18n.locale;
            
            if (locale.includes('en')) {
                return 'en';
            } else {
                return 'zh';
            }
        }
    },


    methods: {
        loadToEventPage(event) {
            const currentLang = this.$i18n.locale.includes('en') ? 'en' : 'zh';

            const category = event.category[currentLang];
            const eventTitle = event[currentLang].title;

            this.$router.push({
                path: `/events/${category}/${eventTitle}`
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .event-photo{
        width: 100%;
        img {
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .sub-title{
        font-size: 28px;
        font-weight: 400;
        color:#2c2c2c;
        cursor: pointer;
        transition: color 0.3s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .evnet-item {
        position: relative;
        &:hover .sub-title{
            color: $color_secondary;
        }
    }

    .activityDate {
        color: #a4a4a4;
        font-size: 17px;
        letter-spacing: 0.05rem;
        padding: 0 2px;
    }

    .event-description{
        color: #666;
        font-size: 16px;
        font-weight: 300;
        margin: 15px 0;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        max-height: 96px;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }

    .column-sort { 
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.2s ease;
        border: solid 1px #ebebeb;
        &:hover {
            box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.17);
            .event-photo img {
                transform: scale(1.05);
            }
        }

        &.evnet-item {
            background: #fff;
            height: 100%;
            display: grid;
            grid-template-rows: auto 1fr;
        }

        .event-photo{
            max-width: 100%;
            flex: 0 0 100%;
            cursor: pointer;
            img {
                transform: scale(1);
                transition:transform 0.3s ease;
                height: 255px;
            }
        }

        .event-info{
            padding: 20px 15px 30px;
            display: grid;
            grid-template-rows: auto auto 1fr auto;
        }
    }

    .row-sort{
        display: flex;
        align-items: flex-end;
        .event-photo{
            max-width: 50%;
            flex: 0 0 50%;
            img {
                height: 320px;
            }
        }

        .event-info{
            max-width: 50%;
            flex: 0 0 50%;
            padding:  0 15px;
        }
    }

    @media screen and (max-width: 1024px) {
        .event-description{
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            max-height: 72px;
        }
    }

    @media screen and (max-width: 576px) {
        .more,
        .activityDate,
        .event-description{
            display: none;
        }

        .sub-title {
            color: #fff;
            font-size: 18px;
            margin-bottom: 0;
        }

        .event-info{
            max-width: 100%;
            width: 100%;
            flex: 0 0 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.7);
        }

        .event-item {
            &:hover {
                .event-title {
                    color:  transparent;
                }

                .active .event-title{
                    color: $color_primary;
                }
            }
        }

        .column-sort{
            .event-info{
                padding: 15px 16px;
            }
        }

        .row-sort {
            .event-photo {
                max-width: 100%;
                flex: 0 0 100%;
                img {
                    height: 255px;
                }
            }

            .event-info{
                padding: 15px 16px;
                max-width: 100%;
                flex: 0 0 100%;
            }
        }

    }
</style>